import { ethers } from "ethers";
import quests from "./questInfo";

export const roundedNum = (num: number, digits: number) => {
	const multiplier = 10 ** digits;
	return Math.floor(num * multiplier) / multiplier;
};

export const maxAllowance = ethers.utils.parseEther("10000000000");

export const timeConverter = (d: number) => {
	d = Number(d);
	var y = Math.floor(d / 86400);
	var h = Math.floor((d % 86400) / 3600);
	var m = Math.floor(((d % 86400) % 3600) / 60);
	// var s = Math.floor(((d % 86400) % 3600) % 60);

	var yDisplay = y > 0 ? y + "d " : "";
	var hDisplay = h > 0 ? h + "h " : "";
	var mDisplay = m > 0 ? m + "m " : "";
	// var sDisplay = s > 0 ? s + "s " : "";
	return yDisplay + hDisplay + mDisplay; // seconds removed for brevity
};

export const nameCleanup = (name: string) => {
	const index = name.search("]");
	return name.slice(index + 1);
};

export const nextLevel = (levelVar: number) => {
	if (levelVar === 1) {
		return 90;
	} else {
		return (90 * 1.1 ** (levelVar - 1));
	}
};

export const levelCalculator = (exp: number): number => {
	if (exp < 90) {
		return 1;
	}

	let level = 1;
	let total = 0;
	let i = 0;
	while (total < exp) {
		//console.log(`INPUT: ${exp} . TOTAL IS ${total}; NEXT IS ${nextLevel(level + i)}`)
		total = total + nextLevel(level + i);

		if (total <= exp) {
			i++;
		}
	}
	return level + i;
};

export const currentLevelMinXp = (level: number) => {
	if (level === 1) {
		return 0;
	}

	let total = 0;

	for (let i = 1; i < level; i++) {
		total = total + nextLevel(i);
	}

	return total;
};

export const questIconResolver = (questString: string) => {
	if (questString == "GRIMWEED") {
		return "https://storage.googleapis.com/crypto-raiders-assets/web/currencies/grimweed.png";
	} else if (questString == "NEWT") {
		return "https://storage.googleapis.com/crypto-raiders-assets/web/currencies/eye_of_newt.png";
	} else if (questString == "SPOREBARK") {
		return "https://storage.googleapis.com/crypto-raiders-assets/web/currencies/sporebark.png";
	} else if (questString == "MHP") {
		return "https://storage.googleapis.com/crypto-raiders-assets/web/currencies/minor_health_potion1.png";
	} else if (questString == "AURUM") {
		return "https://storage.googleapis.com/crypto-raiders-assets/web/currencies/aurum.png"
	}
};


export const materialIconResolver = (questString: string) => {
	if (questString == process.env.NEXT_PUBLIC_GRIMWEED) {
		return "https://storage.googleapis.com/crypto-raiders-assets/web/currencies/grimweed.png";
	} else if (questString == process.env.NEXT_PUBLIC_NEWT) {
		return "https://storage.googleapis.com/crypto-raiders-assets/web/currencies/eye_of_newt.png";
	} else if (questString == process.env.NEXT_PUBLIC_SPOREBARK) {
		return "https://storage.googleapis.com/crypto-raiders-assets/web/currencies/sporebark.png";
	} else if (questString == process.env.NEXT_PUBLIC_AURUM) {
		return "https://storage.googleapis.com/crypto-raiders-assets/web/currencies/aurum.png"
	} else if (questString == process.env.NEXT_PUBLIC_MINOR_HEALTH_POTION) {
		return "https://storage.googleapis.com/crypto-raiders-assets/web/currencies/minor_health_potion1.png";
	} else if (questString == process.env.NEXT_PUBLIC_MINOR_DAMAGE_POTION) {
		return "https://storage.googleapis.com/crypto-raiders-assets/web/currencies/minor_damage_potion.png";	
	} else {
		throw 'Icon: cant find!' + questString
	}
};

export const materialNameResolver = (questString: string) => {
	if (questString == process.env.NEXT_PUBLIC_GRIMWEED) {
		return "GRIMWEED";
	} else if (questString == process.env.NEXT_PUBLIC_NEWT) {
		return "NEWT";
	} else if (questString == process.env.NEXT_PUBLIC_SPOREBARK) {
		return "SPOREBARK";
	} else if (questString == process.env.NEXT_PUBLIC_AURUM) {
		return "AURUM"
	} else if (questString == process.env.NEXT_PUBLIC_MINOR_HEALTH_POTION) {
		return "MHP";
	} else if (questString == process.env.NEXT_PUBLIC_MINOR_DAMAGE_POTION) {
		return "MDP";
	} else {
		throw 'Name: cant find!' + questString
	}
};


export const statIconResolver = (statString: string) => {
	if (statString == "Herbalism") {
		return "https://storage.googleapis.com/crypto-raiders-assets/web/currencies/herbalism.png"
	} else if (statString == "Alchemy") {
		return "https://storage.googleapis.com/crypto-raiders-assets/web/currencies/emptyVial.png"
	}
}

export const questNameLookup = (questString: string) => {
	return quests.find(q => q.level == questString)?.title;
};

export const questRewardLookup = (questName: string) => {
	if (questName == "GRIMWEED") {
		return "Grimweed";
	} else if (questName == "NEWT") {
		return "Eye of Newt";
	}  else if (questName == "SPOREBARK") {
		return "Sporebark";
	} else if (questName == "MHP") { 
		return "Minor Health Potion"
	}
};
