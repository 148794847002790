
interface QuestDescriptor {
    readonly title: string;
    readonly location:string;
    readonly image:string;
    readonly level:string; //actually is currency symbol
    readonly rewards:string;
    readonly experience:string;
    readonly description: string;
    readonly icon: string;
}

const quests = [
    {
        title:"The Hunt for Grimweed",
        location:"Aeurshire Castle Gardens",
        image:"item-skull",
        level:"GRIMWEED",
        rewards:"Grimweed Herbs",
        experience:"Herbalism",
        description: "Auershire's chief medic promised you training if you brought her some Grimweed. Known for its healing properties, brewing it into a potion would surely help you on your adventures. Perhaps Old Man Clance has some in his garden...",
        icon: "https://storage.googleapis.com/crypto-raiders-assets/web/currencies/grimweed.png"
    },
    {
        title:"Newt Slayer",
        location:"Aeurshire Sewers",
        image:"item-skull",
        level:"NEWT",
        rewards:"Eye of Newt",
        experience:"Herbalism",
        description: "You'll need to find some Eye of Newt if you want to brew your first potion, so down into the sewers you go to find the slippery guys. Be careful, though. There are rumors of witches living there. They might turn you into a newt if you run into them...",
        icon: "https://storage.googleapis.com/crypto-raiders-assets/web/currencies/eye_of_newt.png"
    },
    {
        title:"Fungal Infestation",
        location:"Direholt Woods",
        image:"item-skull",
        level:"SPOREBARK",
        rewards:"Sporebark",
        experience:"Herbalism",
        description: "A mysterious elder druid appeared in town one day begging for help. There's a sporebark infestation killing the trees and brush in the nearby woods of Direholt. Helping her could allow you to collect the fungus for use in your own potion experiments…",
        icon: "https://storage.googleapis.com/crypto-raiders-assets/web/currencies/sporebark.png"
    }
] as QuestDescriptor[];

export default quests;