import Head from "next/head";
import Shell from "../components/ui/layouts/Shell";
import axios from "axios";
import { Box, Heading, Text, Flex, Grid, GridItem } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
	raiderBalance,
	aurumBalance,
	materialBalance,
} from "../util/web3/interactBank";
import { roundedNum } from "../util/helpers";
import { GiWallet } from "react-icons/gi";
import CurrencyCard from "../components/ui/card/CurrencyCard";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";

interface HomeProps {
	raiderPrice: number;
	aurumPrice: number;
	grimweedPrice: number;
	newtPrice: number;
}

const Home = (props: HomeProps) => {
	const [aurum, setAurum] = useState<number>(0);
	const [raider, setRaider] = useState<number>(0);
	const [grimweed, setGrimweed] = useState<number>(0);
	const [newt, setNewt] = useState<number>(0);
	const [mhp, setMhp] = useState<number>(0);
	const [bhp, setBhp] = useState<number>(0);
	const [mdp, setMdp] = useState<number>(0);
	const [bdp, setBdp] = useState<number>(0);
	const { account } = useWeb3React();


	const [sporebark, setSporebark] = useState<number>(0);

	useEffect(() => {
		const fetchData = async () => {
			const calls = [
				raiderBalance(),
				aurumBalance(),
				materialBalance("GRIMWEED"), 
				materialBalance("EYE OF NEWT"),
				materialBalance("MHP"),
				materialBalance("BHP"),
				materialBalance("SPOREBARK"),
				materialBalance("MDP"),
				materialBalance("BDP"),
			]
			Promise.all(calls).then(responses => {
				if (responses[0]) {
					setRaider(roundedNum(parseFloat(responses[0]), 2));
				}
				if (responses[1]) {
					setAurum(roundedNum(parseFloat(responses[1]), 2));
				}
				setGrimweed(roundedNum(parseFloat(responses[2]), 2));
				setNewt(roundedNum(parseFloat(responses[3]), 2));
				setMhp(roundedNum(parseFloat(responses[4]), 2));
				setBhp(roundedNum(parseFloat(responses[5]), 2));
				setSporebark(roundedNum(parseFloat(responses[6]), 2));
				setMdp(roundedNum(parseFloat(responses[7]), 2));
				setBdp(roundedNum(parseFloat(responses[8]), 2));

			})
		};
	
		// const connecting = async () => {
		//   await connectWallet(toast);
		// }
	
		fetchData();
	},[account])


	return (
		<Shell>
			<Head>
				<title>Crypto Raiders Wallet</title>
			</Head>
			<Box as="section" bg={"gray.800"}>
				<Box bg="gray.700" pt="20" pb="20">
					<Box
						maxW={{ base: "xl", md: "7xl" }}
						mx="auto"
						// px={{ base: "6", md: "8" }}
						pb="0"
						pr="5"
					>
						<Flex>
							<Box mb="0" color="white" maxW="xl">
								<Heading
									size="2xl"
									letterSpacing="tight"
									fontWeight="extrabold"
									lineHeight="normal"
								>
									<GiWallet /> Raider Wallet
								</Heading>
								<Text fontSize="lg" mt="0" fontWeight="medium" color="gray.200">
									Your wallet is where you can see and trade all your Crypto
									Raiders on-chain economy assets.
								</Text>
								<Text fontSize="sm" mt="4" color="gray.400"></Text>
								{/* <Button
									colorScheme="green"
									minW="40"
									size="lg"
									mt="10"
									onClick={() => claimAllRewards()}
								>
									Claim All Rewards
								</Button> */}
							</Box>
							{/* <Spacer />
							<Image src="/banker.gif" alt="Crypto Raiders banker" maxW={250} /> */}
						</Flex>
					</Box>
				</Box>
				<Box as="section" bg={"gray.800"} p="10" mt="10">
					<Box maxW="7xl" mx="auto" px={{ base: "6", md: "8" }} mt="-20">
						<Grid
							mt={50}
							templateRows="repeat(2, 1fr)"
							templateColumns="repeat(2, 1fr)"
							gap={8}
						>
							<GridItem>
								<CurrencyCard
									name="RAIDER"
									image="https://storage.googleapis.com/crypto-raiders-assets/web/currencies/raider_coin.png"
									price={props.raiderPrice}
									balance={raider}
									shortDescription="The governance token for Crypto Raiders"
									longDescription="Holding RAIDER earns you special privileges in the Crypto Raiders game, and allows you to stake it for a share of all in-game purchases."
									link="https://polygon.sushi.com/swap?inputCurrency=ETH&outputCurrency=0xcd7361ac3307D1C5a46b63086a90742Ff44c63B3"
								/>
							</GridItem>
							<GridItem>
								<CurrencyCard
									name="AURUM"
									image="https://storage.googleapis.com/crypto-raiders-assets/web/currencies/aurum.png"
									price={props.aurumPrice}
									balance={aurum}
									shortDescription="The in-game currency for Crypto Raiders"
									longDescription="AURUM is the currency you'll use for all transactions in the Crypto Raiders ecosystem."
									link="https://polygon.sushi.com/swap?inputCurrency=ETH&outputCurrency=0x34d4ab47Bee066F361fA52d792e69AC7bD05ee23"
								/>
							</GridItem>
							<GridItem>
								<CurrencyCard
									name="GRIMWEED"
									image="https://storage.googleapis.com/crypto-raiders-assets/web/currencies/grimweed.png"
									price={props.grimweedPrice}
									balance={grimweed}
									shortDescription="A basic herb for concocting potions"
									longDescription="Grimweed is the first herb you'll encounter in your adventures. It can be used to brew health potions, among other things."
									link="https://polygon.sushi.com/swap?inputCurrency=0x34d4ab47Bee066F361fA52d792e69AC7bD05ee23&outputCurrency=0x06F34105B7DfedC95125348A8349BdA209928730"
								/>
							</GridItem>
							<GridItem>
								<CurrencyCard
									name="EYE OF NEWT"
									image="https://storage.googleapis.com/crypto-raiders-assets/web/currencies/eye_of_newt.png"
									price={props.newtPrice}
									balance={newt}
									shortDescription="A common ingredient in potion making"
									longDescription="Newt eyes have always had strange mystical properties. Perhaps combined with the right herbs, you can make something useful."
									link="https://polygon.sushi.com/swap?inputCurrency=0x34d4ab47Bee066F361fA52d792e69AC7bD05ee23&outputCurrency=0x1346FdB62241e238Be9F84A2FC364c0657757015"
								/>
							</GridItem>
							<GridItem>
								<CurrencyCard
									name="MINOR HEALTH POTION"
									image="https://storage.googleapis.com/crypto-raiders-assets/web/currencies/minor_health_potion_bridge.png"
									balance={mhp}
									shortDescription="A basic potion for restoring health"
									longDescription="These potions are sure to help you endure your more challenging adventures. Perhaps with enough study, you can make more powerful ones as well."
									link={`https://polygon.sushi.com/swap?inputCurrency=0x34d4ab47Bee066F361fA52d792e69AC7bD05ee23&outputCurrency=${process.env['NEXT_PUBLIC_MINOR_HEALTH_POTION']}`}
								/>
							</GridItem>
							<GridItem>
								<CurrencyCard
									name="BASIC HEALTH POTION"
									image="https://storage.googleapis.com/crypto-raiders-assets/web/currencies/basic_health_potion_bridge.png"
									balance={bhp}
									shortDescription="The second-tier health potion, for restoring 200 health in combat."
									longDescription="A slightly more potent solution, these potions will keep you in the fight longer while taking up the same amount of space in your bag."
									link={`https://polygon.sushi.com/swap?inputCurrency=0x34d4ab47Bee066F361fA52d792e69AC7bD05ee23&outputCurrency=${process.env['NEXT_PUBLIC_BASIC_HEALTH_POTION']}`}
								/>
							</GridItem>
							<GridItem>
								<CurrencyCard
									name="SPOREBARK"
									image="https://storage.googleapis.com/crypto-raiders-assets/web/currencies/sporebark.png"
									balance={sporebark}
									shortDescription="Not used yet."
									longDescription="Where there’s moisture and plants, there’s sporebark. It unsurprisingly reeks of rot as it saps the life of its host—something you can use to your advantage in potion crafting."
								/>
							</GridItem>
							<GridItem>
								<CurrencyCard
									name="MINOR DAMAGE POTION"
									image="https://storage.googleapis.com/crypto-raiders-assets/web/currencies/minor_damage_potion_bridge.png"
									balance={mdp}
									shortDescription="A basic potion for increasing damage"
									longDescription="Good for gaining a small edge in combat, damage potions will help you deal extra damage to enemies for a short duration of turns."
								/>
							</GridItem>
							<GridItem>
								<CurrencyCard
									name="BASIC DAMAGE POTION"
									image="https://storage.googleapis.com/crypto-raiders-assets/web/currencies/basic_damage_potion_bridge.png"
									balance={bdp}
									shortDescription="The second-tier damage potion."
									longDescription="Not for the faint of heart, these damage potions will guarantee you hit harder for longer."
								/>
							</GridItem>
						</Grid>
					</Box>
				</Box>
			</Box>
		</Shell>
	);
};

export default Home;

export const uniABI = [
	"function getReserves() public view returns (uint112 _reserve0, uint112 _reserve1, uint32 _blockTimestampLast)",
	"function totalSupply() external view returns (uint)"
]

export async function getStaticProps() {
	// const maticUSD = await axios
	// 	.get(
	// 		"https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0&vs_currencies=usd"
	// 	)
	// 	.then((res) => {
	// 		return res.data["0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0"].usd;
	// 	});


	let raiderPrice = 0;

	// Sometimes Sushi changes if it prices in MATIC or ETH, you can change that here too.
	

	const prov = new ethers.providers.JsonRpcProvider(process.env.NEXT_PUBLIC_ALCHEMY_API_URL)

	//const raiderETHLP = new ethers.Contract("0x426a56f6923c2b8a488407fc1b38007317ecafb1", uniABI, prov)
	

	try {
		raiderPrice = await axios
		.get(
			"https://api.coingecko.com/api/v3/simple/price?ids=crypto-raiders&vs_currencies=usd"
		)
		.then((res) => {
			return res.data["crypto-raiders"].usd;
		});

	} catch (err) {
		console.log('lp pool failure')
	}

	let aurumPrice = 0;
	let grimweedPrice =  0;
	let newtPrice = 0;

	const get_price = async (contract: ethers.Contract, priceMultipler: number) => {
		const resp = await contract.getReserves()
		const base = Number(ethers.utils.formatEther(resp._reserve1)) / Number(ethers.utils.formatEther(resp._reserve0))
		const ratio = (base * (priceMultipler / (10 ** 18)))
		return ratio
	}

	try {
		aurumPrice = await axios.get(
			"https://api.coingecko.com/api/v3/simple/price?ids=raider-aurum&vs_currencies=usd"
		).then((res) => {
			return res.data["raider-aurum"].usd;
		});
		const GrimweedAurumLP = new ethers.Contract("0x89176Dc7b5AEBd0D5eFfFc2592e2068266F4b783", uniABI, prov)
		grimweedPrice = (await get_price(GrimweedAurumLP, aurumPrice))
		const NewtAurumLP = new ethers.Contract("0x1Fec149e363b9c3282C2C3509f171cE2Ac77dd27", uniABI, prov)
		newtPrice = (await get_price(NewtAurumLP, aurumPrice))
	} catch (err) {
		console.log(err)
		console.log("pricing failure")
	}

	return {
		props: {
			raiderPrice,
			aurumPrice,
			grimweedPrice,
			newtPrice,
		},
		revalidate: 30,
	};
}
