import React from "react";
import {
	Box,
	Text,
	HStack,
	Button,
	Image,
	Stack,
	useColorModeValue,
} from "@chakra-ui/react";

interface CurrencyCardProps {
	name: string;
	image: string;
	price?: number;
	balance: number;
	shortDescription: string;
	longDescription: string;
	link?: string; //Not all assets has LP yet.
}

export default function CurrencyCard(props: CurrencyCardProps) {
	return (
		<Box
			maxW="xl"
			mx="auto"
			bg={useColorModeValue("white", "gray.700")}
			rounded={{ md: "xl" }}
			padding="6"
			shadow={{ md: "base" }}
			minH="200px"
			px={{ base: "6", md: "8" }}
		>
			<Stack
				direction={{ base: "column", md: "row" }}
				spacing={{ base: "3", md: "10" }}
				align="flex-start"
				alignItems="center"
				alignContent="center"
			>
				<Stack spacing="4" textAlign="center" alignItems="center">
					<Image
						src={props.image}
						alt={props.name}
						width="60px"
						height="60px"
						borderRadius="full"
					/>
					<Button
						width="full"
						colorScheme="green"
						as="a"
						href={props.link}
						target="_blank"
						_hover={{ textDecoration: "none" }}
						disabled={!props.link}
					>
						Trade
					</Button>
				</Stack>
				<Box>
					<Stack
						spacing={{ base: "1", md: "2" }}
						direction={{ base: "column", md: "row" }}
					>
						<Text as="h2" fontWeight="bold" fontSize="xl">
							{props.name}
						</Text>
						<HStack fontSize={{ base: "md", md: "lg" }}>
							<Text
								as="span"
								color={useColorModeValue("gray.500", "gray.300")}
								lineHeight="1"
							>
								{/* Balance: {Number(props.balance).toLocaleString()} */}
							</Text>
						</HStack>
					</Stack>
					{/* <Text mt="2">{props.shortDescription}</Text> */}
					<Text mt="2">
						{props.price != undefined && <span>Current price: ${Number(props.price).toLocaleString()} | </span>
						}Your
						Balance: {Number(props.balance).toLocaleString()}
					</Text>

					<Box mt={3} fontSize="sm">
						{props.longDescription}
					</Box>
				</Box>
			</Stack>
		</Box>
	);
}
